import { useState, useEffect } from 'react';
import { processUserInput } from '../services/ai/core';
import { ChatMessage } from '../services/ai/types';

const STORAGE_KEY = 'ilu2_messages';
const STALE_THRESHOLD = 24 * 60 * 60 * 1000; // 24 hours

export function useChat() {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState<ChatMessage[]>(() => {
    const saved = localStorage.getItem(STORAGE_KEY);
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(messages));
  }, [messages]);

  const initializeConversation = () => {
    const initialMessages: ChatMessage[] = [
      {
        id: Date.now(),
        text: "Hi! I'm ilu2. What matters to you?",
        sender: 'bot',
        timestamp: Date.now(),
      },
    ];
    setMessages(initialMessages);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(initialMessages));
  };

  const handleSubmit = async () => {
    if (!input.trim()) return;

    const userMessage: ChatMessage = {
      id: Date.now(),
      text: input,
      sender: 'user',
      timestamp: Date.now(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput('');

    if (messages.length === 1) {
      const followUpMessage: ChatMessage = {
        id: Date.now() + 1,
        text: 'Got it. What should you do?',
        sender: 'bot',
        timestamp: Date.now(),
      };
      setMessages((prev) => [...prev, followUpMessage]);
      return;
    }

    const aiResponse = await processUserInput(input);
    const botMessage: ChatMessage = {
      id: Date.now() + 1,
      text: aiResponse.reflection,
      sender: 'bot',
      timestamp: Date.now(),
    };

    setMessages((prev) => [...prev, botMessage]);
  };

  return {
    messages,
    input,
    setInput,
    handleSubmit,
    initializeConversation,
  };
}
