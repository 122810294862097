import { useEffect, useState } from 'react';
import { getRandomArrayElements } from '../../Games/Lobby/Games/CodeNames/utils';
import { CorePrinciples } from '../../ilu2/principles';
import {
  analyzeSelfAttunement,
  detectEmotionalState,
  calculateAttunementLevel,
  generateAttunementSuggestions,
} from '../../ilu2/analysis/selfAttunement';
import {
  EmotionalState,
  SelfAttunementAnalysis,
} from '../../ilu2/analysis/types';

const OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export function useAi() {
  const [data, setData] = useState<unknown[]>([]);

  async function askAi(input: {
    type: string;
    input: string;
    reflection?: string;
  }) {
    if (input.type === 'ilu2_reflection') {
      const attunement = analyzeSelfAttunement(input.input);
      const response = {
        timestamp: Date.now(),
        source: {
          author: 'AI',
          principle: CorePrinciples.relation.title,
        },
        analysis: {
          attunement,
        },
        reflection: generateReflection(input.input, attunement),
      };

      setData([...data, response]);
      return response.reflection;
    }
    return null;
  }

  function generateReflection(input: string, analysis: any): string {
    const emotionalState = detectEmotionalState(input);
    const suggestions = generateAttunementSuggestions(
      analysis.markers,
      emotionalState
    );

    // Almost always default to "What should you do?"
    return suggestions[0];
  }

  function hasStrongEmotions(emotionalState: EmotionalState): boolean {
    return emotionalState.intensity ? emotionalState.intensity > 0.7 : false;
  }

  function getEmotionalStateDescription(
    emotionalState: EmotionalState
  ): string {
    const emotions = [];
    if (emotionalState.happy) emotions.push('happy');
    if (emotionalState.sad) emotions.push('sad');
    if (emotionalState.fear) emotions.push('fearful');
    if (emotionalState.anger) emotions.push('angry');

    if (emotions.length === 0) return 'uncertain';
    if (emotions.length === 1) return emotions[0];

    const last = emotions.pop();
    return `${emotions.join(', ')} and ${last}`;
  }

  function generateNextAction(
    input: string,
    emotionalState: EmotionalState
  ): string {
    if (emotionalState.anger || emotionalState.fear) {
      return 'What do you need right now to feel more grounded?';
    }

    if (emotionalState.sad) {
      return 'What kind of support would feel helpful in this moment?';
    }

    if (emotionalState.happy) {
      return 'How might you build on this positive energy?';
    }

    return 'What small step would help you move forward?';
  }

  function generateGrowthReflection(
    input: string,
    attunement: SelfAttunementAnalysis
  ): string {
    const questions = [
      "What's working that you can do more of?",
      'What would you like to do differently?',
      "What's one thing you've learned?",
    ];

    return questions[Math.floor(Math.random() * questions.length)];
  }

  function tellAi(val: unknown) {
    setData([...data, val]);
  }

  return { askAi, tellAi };
}

export default function AI() {
  const [options, setOptions] = useState<React.ReactNode[]>([]);
  const { askAi, tellAi } = useAi();

  function refreshOptions() {
    const newOptions = getRandomArrayElements(OPTIONS, 2);
    setOptions(newOptions);
    askAi({ type: 'ilu2_reflection', input: newOptions.join(' ') });
  }

  useEffect(() => {
    refreshOptions();
  }, []);

  function handleSelectOption(val: unknown) {
    tellAi(val);
    refreshOptions();
  }

  return (
    <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
      {options.map((option) => (
        <button
          onClick={() => handleSelectOption(option)}
          style={{ borderRadius: '1rem', height: '3rem', width: '4rem' }}
        >
          {option}
        </button>
      ))}
    </div>
  );
}
