export interface Modality {
  available: boolean;
  title: string;
  description?: JSX.Element;
  prompt?: string;
}

const FiveMinuteJournalFigcaption = () => (
  <figcaption className="text-muted">
    <h1>Sources:</h1>
    <div>
      - Kevin Evans,{' '}
      <cite>
        <a
          href="https://www.intelligentchange.com/blogs/read/the-five-minute-journal-questions"
          target="_blank"
          rel="noreferrer"
        >
          The Five Minute Journal Questions
        </a>
      </cite>
    </div>
    <div>
      - Tim Ferriss,{' '}
      <cite>
        <a
          href="https://tim.blog/wp-content/uploads/2018/07/5-morning-rituals-that-help-me-win-the-day-july2018.pdf"
          target="_blank"
          rel="noreferrer"
        >
          5 Morning Rituals That Help Me Win The Day
        </a>
      </cite>
    </div>
  </figcaption>
);

// TODO alphabetical order please
// Modalities are writing exercises, consider renaming
// These should maybe live in the database, only exceptional attributes have to live here if at all
export const MODALITIES: { [modalityKey: string]: Modality } = Object.freeze({
  healthyrelating: {
    available: false,
    title: 'Healthy Relating',
    description: (
      <p>
        Usually, this is where you can read more information about the selected
        healthy relating <strong>modality</strong>. [This is a test; 'Healthy
        Relating' is not really a "modality".]
      </p>
    ),
  },
  ownershiplanguage: {
    available: false,
    title: 'Ownership Language',
    description: (
      <ul>
        <li>
          <blockquote>
            "language that identifies the source of the information that is
            being shared"
            <ul>
              <li>"I think..."</li>
              <li>"I read this in..."</li>
              <li>"In my opinion..."</li>
              <li>"My experience is…"</li>
              <li>"This was someone else's experience…"</li>
              <li>"The information I have been taught on this subject is…"</li>
              <li>
                "This is what I've been told, but it isn't necessarily my
                experience…"
              </li>
            </ul>
            <cite>
              —{' '}
              <a
                href="https://www.onecommunityglobal.org/conscious-and-conscientious-communication/"
                target="_blank"
                rel="noreferrer"
              >
                Conscious and Conscientious Communication
              </a>
            </cite>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              "In many of my classes our teachers have been training us to use
              "I statements" during discussions. For example, it applies when
              you fall into the habit of using we, or you, when you actually
              mean "I" in a statement, as I just did in this sentence. See what
              I did there? Every time I used we or you in that sentence, I
              really was referring to how I feel about the subject, but I was
              not using ownership language."
            </p>
            <figcaption>
              — Beth Lykins,{' '}
              <cite>
                <a
                  href="https://www.swc.edu/student-alum-stories/part-1-3-series-ownership-language/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Part 1 of 3 in a Series About Ownership Language
                </a>
              </cite>
            </figcaption>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>"self-responsible language"</p>
            <blockquote className="mx-5">
              <small className="text-muted">
                <p>
                  I'm hesitant to include this resource; I consider it a
                  misleading example of ownership language. He injects wacky
                  embelishments, twists for self-empowerment, and hidden
                  formulaic if-then certainties.
                </p>
                <p>
                  But it's a video resource which you may like. I find it
                  entertaining. And it's a potential talking point for further
                  disambguation of terms and methodologies.
                </p>
                <figcaption>— Geoffrey Hale</figcaption>
              </small>
            </blockquote>
            <figcaption>
              — Thomas Haller,{' '}
              <cite>
                <a
                  href="https://vimeo.com/123997338"
                  target="_blank"
                  rel="noreferrer"
                >
                  TTA: Ownership Language
                </a>
              </cite>
            </figcaption>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <blockquote className="mx-5">
              <small className="text-muted">
                <p>
                  This video hurts my heart. It condones manipulative/misleading
                  communication in the name of "ownership language". Please do
                  not do this. Please approach all communication on this site
                  with a spirit of honesty, authenticity, and integrity.
                </p>
                <figcaption>— Geoffrey Hale</figcaption>
              </small>
            </blockquote>
            <figcaption>
              — Cory Henwood,{' '}
              <cite>
                <a
                  href="https://www.youtube.com/watch?v=NQAZps4Phlw"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ownership Language
                </a>
              </cite>
            </figcaption>
          </blockquote>
        </li>
      </ul>
    ),
  },
  istatements: {
    available: true,
    title: '"I" Statements',
    description: (
      <blockquote>
        <p>
          "In many of my classes our teachers have been training us to use "I
          statements" during discussions. For example, it applies when you fall
          into the habit of using we, or you, when you actually mean "I" in a
          statement, as I just did in this sentence. See what I did there? Every
          time I used we or you in that sentence, I really was referring to how
          I feel about the subject, but I was not using ownership language."
        </p>
        <figcaption>
          — Beth Lykins,{' '}
          <cite>
            <a
              href="https://www.swc.edu/student-alum-stories/part-1-3-series-ownership-language/"
              target="_blank"
              rel="noreferrer"
            >
              Part 1 of 3 in a Series About Ownership Language
            </a>
          </cite>
        </figcaption>
      </blockquote>
    ),
  },
  somaticcheckin: {
    available: true,
    title: 'Somatic Check-In',
    description: (
      <>
        <p>Relating to the body, especially as distinct from the mind.</p>
        <p>Notice how you are feeling right now in your body.</p>
        <p>More:</p>
        <ul>
          <li>
            <a
              href="https://gravitywerks.com/a-somatic-check-in-anyone-can-do/"
              target="_blank"
              rel="noreferrer"
            >
              A Somatic Check-in Anyone Can Do
            </a>
          </li>
        </ul>
      </>
    ),
  },
  reflection: {
    available: true,
    title: 'Reflection',
    description: (
      <>
        <ul>
          <li>
            <blockquote>
              <p>
                ...if you and your team stop working 15-minutes before you walk
                out the office door or log off your computer and reflect on what
                you learned, you can boost productivity over 20 percent.
              </p>
              <ul>
                <li>
                  Boosts productivity quickly — In just 10 days it increases
                  performance double digits.
                </li>
                <li>
                  Has a lasting impact — A month later the employees still had a
                  noticeably higher level of productivity.
                </li>
              </ul>
              <ul>
                <li>Translating experiences into practical wisdom</li>
                <li>Building confidence and motivation</li>
              </ul>
              <p>15 minutes daily, what few lessons have you learned today?</p>
              <figcaption>
                — Ben Fanning,{' '}
                <cite>
                  <a
                    href="https://www.benfanning.com/boost-team-productivity/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    This Counterintuitive Work Strategy Boosts Productivity Over
                    20%
                  </a>
                </cite>
              </figcaption>
            </blockquote>
          </li>
          <li>
            <blockquote>
              <p>
                <ul>
                  <li>Question 1: What's working that you can do more of?</li>
                  <li>
                    Question 2: What's not working and would you like to do
                    differently?
                  </li>
                  <li>Question 3: What's one thing you've learned?</li>
                </ul>
              </p>
              <figcaption>
                — Ben Fanning,{' '}
                <cite>
                  <a
                    href="https://gravitywerks.com/a-somatic-check-in-anyone-can-do/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    A Somatic Check-in Anyone Can Do
                  </a>
                </cite>
              </figcaption>
            </blockquote>
          </li>
        </ul>
      </>
    ),
  },
  emotionalawareness: {
    available: true,
    title: 'Emotional Awareness',
    description: (
      <>
        <p>This tool seeks to increase our emotional awareness.</p>
        <p>
          Emotion words in the card below will turn bold if you type them in
          your post.
        </p>
        <p>This is a proof of concept, under construction.</p>
        <blockquote>
          <p>
            "four categories, namely happy, sad, fear/surprise (i.e.,
            fast-approaching danger) and disgust/anger (i.e., stationary
            danger)"
          </p>
          <figcaption>
            — Rachael E. Jack,{' '}
            <cite>
              <a
                href="https://www.cell.com/current-biology/fulltext/S0960-9822(13)01519-4"
                target="_blank"
                rel="noreferrer"
              >
                Dynamic Facial Expressions of Emotion Transmit an Evolving
                Hierarchy of Signals over Time
              </a>
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  vulnerability: {
    available: true,
    title: 'Vulnerability',
    description: (
      <>
        <p>
          This tool seeks to encourage a courageous practice of vulnerability.
        </p>
        <p>
          This tool seeks to help you be recognized in your courageous acts of
          vulnerable sharing.
        </p>
      </>
    ),
  },
  triggered: {
    available: true,
    title: 'Triggered',
    description: (
      <>
        <p>
          This is for self-awareness and unpacking your experience when a
          memory, experience, or event sparks an intense emotional reaction.
        </p>
        <p>
          If the post displays self-awareness and seeks to find what's really
          going on inside during the trigger, the post should receive a "Yes"
          vote.
        </p>
        <p>
          Aggressive blameful victim rambling should receive empathy and a "No"
          vote in this modality.
        </p>
      </>
    ),
  },
  possibleselves: {
    // TODO maybe Vulnerability and bestpossibleself skills should be a prerequisite
    available: true,
    title: 'Possible Selves',
    description: (
      <>
        <blockquote>
          <p>
            "Possible selves represent individuals' ideas of what they might
            become, what they would like to become, and what they are afraid of
            becoming"
          </p>
          <figcaption>
            — Markus,{' '}
            <cite>
              <a
                href="https://web.stanford.edu/~hazelm/publications/1986_Markus%20&%20Nurius_PossibleSelves.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Possible Selves
              </a>
            </cite>
          </figcaption>
        </blockquote>
        <blockquote>
          <p>
            If you want to know what drives your actions and what holds you back
            from making decisions or pursuing goals, this exercise is for you.
          </p>
          <p>
            You're going to visualize and describe, in writing, three different
            versions of yourself a year in the future:
          </p>
          <ol>
            <li>
              My Ideal Self - What do you really want for yourself a year from
              now?
            </li>
            <li>
              My Probable Self - What do you expect for yourself a year from
              now?
            </li>
            <li>
              My Feared Self - What are you most afraid of becoming a year from
              now?
            </li>
          </ol>
          <p>
            ...describe an imaginary scene with you in it, unfettered by
            reality. What are you doing? Where are you? Who else is there?
            Include as many tiny details as you want, such as what you're
            wearing or what sounds are in the background.
          </p>
          <figcaption>
            — Nicola Prentis,{' '}
            <cite>
              <a
                href="https://www.success.com/use-this-writing-exercise-to-uncover-your-ideal-self/"
                target="_blank"
                rel="noreferrer"
              >
                Use This Writing Exercise to Uncover Your Ideal Self
              </a>
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  bestpossibleself: {
    available: true,
    title: 'Best Possible Self',
    description: (
      <>
        <blockquote>
          <p>
            Think about your life in the future. Imagine that everything has
            gone as well as it possibly could. You have worked hard and
            succeeded at accomplishing all of your life goals. Think of this as
            the realization of all of your life dreams. Now, write about what
            you imagined.
          </p>
          <p>
            Don't just think about what you have achieved (e.g., getting your
            dream job), but be sure to write about how you got there (e.g.,
            doing an internship, going to graduate school).
          </p>
          <p>
            Think about your life in the future. Imagine that everything has
            gone as well as it possibly could. You have worked hard and
            succeeded at accomplishing all of your life goals. Think of this as
            the realization of all of your life dreams.
          </p>
          <blockquote>
            <figcaption>
              - Laura A. King,{' '}
              <cite>
                <a
                  href="https://journals.sagepub.com/doi/abs/10.1177/0146167201277003"
                  target="_blank"
                  rel="noreferrer"
                >
                  The Health Benefits of Writing about Life Goals
                </a>
              </cite>
            </figcaption>
          </blockquote>
          <figcaption>
            — Jeff Cobb,{' '}
            <cite>
              <a
                href="https://www.missiontolearn.com/best-possible-self/"
                target="_blank"
                rel="noreferrer"
              >
                Use This Writing Exercise to Uncover Your Ideal Self
              </a>
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  subpersonality: {
    available: true,
    title: 'Subpersonality',
    description: (
      <>
        <blockquote>
          <p>
            "A subpersonality is, in humanistic psychology, transpersonal
            psychology and ego psychology, a personality mode that activates
            (appears on a temporary basis) to allow a person to cope with
            certain types of psychosocial situations."
          </p>
          <p>
            "Subpersonalities are able to perceive consciousness as something
            separate from themselves, as well as domestic image attached to
            these elements."
          </p>
          <p>
            "Many schools of psychotherapy see subpersonalities as relatively
            enduring psychological structures or entities that influence how a
            person feels, perceives, behaves, and sees him-, herself or
            themselves."
          </p>
          <figcaption>
            <cite>
              <a
                href="https://en.wikipedia.org/wiki/Subpersonality"
                target="_blank"
                rel="noreferrer"
              >
                Subpersonality
              </a>
            </cite>
          </figcaption>
        </blockquote>
        <blockquote>
          <p>
            "IFS posits that the mind is made up of multiple parts, and
            underlying them is a person's core or true Self. Like members of a
            family, a person's inner parts can take on extreme roles or
            subpersonalities. Each part has its own perspective, interests,
            memories, and viewpoint. A core tenet of IFS is that every part has
            a positive intent, even if its actions are counterproductive and/or
            cause dysfunction. There is no need to fight with, coerce, or
            eliminate parts; the IFS method promotes internal connection and
            harmony to bring the mind back into balance."
          </p>
          <figcaption>
            <cite>
              <a
                href="https://en.wikipedia.org/wiki/Internal_Family_Systems_Model#Parts"
                target="_blank"
                rel="noreferrer"
              >
                Internal Family Systems Model{' > '}Parts
              </a>
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  gratitude: {
    available: true,
    title: 'Gratitude',
    description: (
      <>
        <blockquote>
          <p>
            The benefits of practicing gratitude are nearly endless. People who
            regularly practice gratitude by taking time to notice and reflect
            upon the things they're thankful for experience more positive
            emotions, feel more alive, sleep better, express more compassion and
            kindness, and even have stronger immune systems.
          </p>
          <figcaption>
            - Derrick Carpenter,{' '}
            <cite>
              <a
                href="https://www.happify.com/hd/the-science-behind-gratitude/"
                target="_blank"
                rel="noreferrer"
              >
                The Science Behind Gratitude (and How It Can Change Your Life),
                Happify
              </a>
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  morningpages: {
    available: true,
    title: 'Morning Pages',
    description: (
      <>
        <blockquote>
          <p>
            "Morning Pages are three pages of longhand, stream of consciousness
            writing, done first thing in the morning. *There is no wrong way to
            do Morning Pages* - they are not high art. They are not even
            "writing." They are about anything and everything that crosses your
            mind..."
          </p>
          <figcaption>
            - Julia Cameron,{' '}
            <cite>
              <a
                href="https://juliacameronlive.com/basic-tools/morning-pages/"
                target="_blank"
                rel="noreferrer"
              >
                Morning Pages
              </a>
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  sevenfiftywords: {
    available: true,
    title: '750 Words',
    description: (
      <>
        <blockquote>
          "
          <p>
            I've long been inspired by an idea I first learned about in The
            Artist's Way called morning pages. Morning pages are three pages of
            writing done every day, typically encouraged to be in "long hand",
            typically done in the morning, that can be about anything and
            everything that comes into your head. It's about getting it all out
            of your head, and is not supposed to be edited or censored in any
            way. The idea is that if you can get in the habit of writing three
            pages a day, that it will help clear your mind and get the ideas
            flowing for the rest of the day. Unlike many of the other exercises
            in that book, I found that this one actually worked and was really
            really useful.
          </p>
          <p>
            I've used the exercise as a great way to think out loud without
            having to worry about half-formed ideas, random tangents, private
            stuff, and all the other things in our heads that we often filter
            out before ever voicing them or writing about them. It's a daily
            brain dump. Over time, I've found that it's also very helpful as a
            tool to get thoughts going that have become stuck, or to help get to
            the bottom of a rotten mood.
          </p>
          "
          <figcaption>
            -{' '}
            <cite>
              <a href="https://750words.com/" target="_blank" rel="noreferrer">
                750words.com
              </a>{' '}
              ($5/mo)
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  moodjournal: {
    available: true,
    title: 'Mood Journal',
    description: (
      <>
        <blockquote>
          <p>
            Keeping a mood diary will help you to notice patterns in your
            thoughts, feelings and behaviours, which you can then address.
            Evidence suggests that one of the main reasons mood diaries have
            such a positive impact on our mental wellbeing is the sense of
            empowerment and control they can bring.
          </p>
          <figcaption>
            -{' '}
            <cite>
              <a
                href="https://www.caba.org.uk/help-and-guides/information/how-use-journaling-improve-your-mood"
                target="_blank"
                rel="noreferrer"
              >
                How To Use Journaling Improve Your Mood, CABA
              </a>{' '}
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  whatishealthyrelating: {
    available: true,
    title: 'What is Healthy Relating?',
    description: <>What does "healthy realting" mean to you?</>,
  },
  miraclequestions: {
    available: true,
    title: 'Miracle Question',
    description: (
      <>
        <p>
          The miracle question is an intervention used to explore your hidden
          resources or solutions for your present problems. When asked a miracle
          question, you build a good story line that leads you to envision how
          different your life would be if a miracle happened over night.
        </p>
        <ul>
          <li>
            If you were to wake up, say, a month from now, what kind of life
            would you ideally wake up to?
          </li>
        </ul>
      </>
    ),
  },
  changeRequest: {
    available: true,
    title: 'Change Request',
    description: (
      <>
        <p>Add a feature request or bug report.</p>
        <p>
          Other users can upvote and downvote for visibility to the xbk dev
          team.
        </p>
      </>
    ),
  },
  question: {
    available: true,
    title: 'Question',
    description: <p>Go ahead, ask anything!</p>,
  },
  diamondFromRough: {
    available: true,
    title: 'Diamond From Rough',
    description: (
      <>
        <ol>
          <li>
            Let myself write a big digression for what value? Write as best or
            poorly as you like but absolutely write as much as you're
            comfortable writing.
          </li>
          <li>
            ...that has you write (optionally for an amount of time or word
            count) and saves that, sure, then has you crush it down to like 20%
            of what it was; 80% of the value in 20% of the size
          </li>
          <li>
            and remember to consider or even discover your audience as you are
            finding that gold nugget vein
          </li>
        </ol>
        <ul>
          <li>Type: Repeated Edits &gt; Diamond From Rough</li>
          <li>
            i like this idea, this is cool, this writing site really could be a
            writing site that helps train writers, even support them developing
            high value content, peer-verified content, hilarious content, viral
            content, etc.
          </li>
          <li>
            for now just write your responses to your edits in comments to your
            post, others can join you if you leave it available for others to
            comment
          </li>
        </ul>
        @TODO:
        <ul>
          <li>add timer</li>
          <li>
            redirect to the published post with reply form open and cursor in
            it, enabling eventual rapid rewriting exercises
          </li>
          <li>default publish w visibility "Just Me"</li>
        </ul>
      </>
    ),
  },
  writingToThink: {
    available: true,
    title: 'Writing To Think',
    description: (
      <>
        <p>
          Writing for your eyes only. Don't forget to toggle "Just Me". You CAN
          share it, but this kind of writing is usually the last thing anyone
          wants to read.
        </p>
        @TODO:
        <ul>
          <li>default publish w visibility "Just Me"</li>
        </ul>
      </>
    ),
  },
  onethirtywords: {
    available: true,
    title: '130 Words',
    description: (
      <>
        <p>130 Words was a free web app built to motivate daily journaling.</p>
        <p>
          Every day that you wrote and submitted at least 130 words was
          considered a success. Your posts were kept private while your success
          and streaks were recognized on a community leaderboard.
        </p>
        <p>
          It was located at{' '}
          <a href="https://130words.com" target="_blank" rel="noreferrer">
            130words.com
          </a>
          .
        </p>
      </>
    ),
    // justMeByDefault: true,
  },
  pdpd: {
    available: true,
    title: 'PDPD',
    description: (
      <>
        <h2>Psychological Displacement Paradigm</h2>
        <p>
          Write a about a negative event using first-person pronouns. Then write
          a second time about the same event using second-person and a third
          time using third-person.
        </p>
        <blockquote>
          <p>
            "PDPD instructs participants to write diary in first-person pronoun
            first, and then narrate the same event from a different perspective
            using second-person pronoun. Finally, the participants write it
            again with third-person pronoun from yet another perspective. These
            three narrations were to be written in a consecutive sequential
            order.""
          </p>
          <figcaption>
            - Mark D. Griffiths Ph.D.,{' '}
            <cite>
              <a
                href="https://www.psychologytoday.com/us/blog/in-excess/201507/writing-wrongs"
                target="_blank"
                rel="noreferrer"
              >
                Writing Wrongs, Psychology Today
              </a>
            </cite>
          </figcaption>
        </blockquote>
        <p>
          Read more thoughts on this from friend and author Samantha Shad at{' '}
          <a
            href="https://samanthashad.medium.com/change-your-pronouns-change-your-life-54c9f6b64bbb"
            target="_blank"
            rel="noreferrer"
          >
            Change Your Pronouns, Change Your Life
          </a>
          .
        </p>
      </>
    ),
  },
  workingBackwards: {
    available: true,
    title: 'Working Backwards',
    description: (
      <>
        <blockquote>
          <p>
            "a problem-solving strategy in which the solver begins at the goal
            state and attempts to find a path back to the problem's starting
            conditions."
          </p>
          <figcaption>
            -
            <cite>
              <a
                href="https://dictionary.apa.org/working-backward"
                target="_blank"
                rel="noreferrer"
              >
                APA Dictionary of Psychology
              </a>
            </cite>
          </figcaption>
        </blockquote>
        <blockquote>
          <p>
            "The 'working backwards' method is defined by a focus on the
            customer need. Instead of starting with the technology, this means
            beginning with the benefits of the technology for the customers, and
            the problem it will solve in their lives."
          </p>
          <figcaption>
            - Nikki Gilliland,{' '}
            <cite>
              <a
                href="https://econsultancy.com/why-the-working-backwards-method-is-key-to-a-superior-customer-experience/"
                target="_blank"
                rel="noreferrer"
              >
                Why the 'working backwards' method is key to a superior customer
                experience, Econsultancy
              </a>
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  entrepreneursStandup: {
    available: true,
    title: "Entrepreneur's Stand-up",
    description: (
      <>
        <blockquote>
          <p>You can re-write and revisit these prompts.</p>
          <ul>
            <li>
              Broad question:
              <br />
              What are the traits your customer has?
              <br />
              What can you do to improve your company's relationship with its
              existing clientele and potential clientele?
            </li>
            <li>What was your previous goal?</li>
            <li>Did you accomplish your goal?</li>
            <li>
              What objective are you focused on in the next two weeks?
              <br />
              Please give this some thought.
            </li>
            <li>What are the steps to meet those objectives?</li>
          </ul>
        </blockquote>
      </>
    ),
  },
  futurescaping: {
    available: true,
    title: 'Futurescaping',
    description: (
      <>
        <blockquote>
          <p>
            "Futurescaping is an engaging guide to making better life decisions
            by adapting the best elements of business planning for personal
            success."
          </p>
          <p>
            "...future planning, outlining the importance and benefit of
            accountability and a clear bottom line. It focuses on the enigmatic
            technique of scenario planning which enables organisations to plan
            decades in advance, and shows how individuals can use the same
            technique to plan for their own futures."
          </p>
          <p>
            "...build a model of your future to help you make smarter choices in
            your career, family life, health and personal finances."
          </p>
          <figcaption>
            - Tamar Kasriel,{' '}
            <cite>
              <a
                href="https://www.bloomsbury.com/us/futurescaping-9781408156971/"
                target="_blank"
                rel="noreferrer"
              >
                Futurescaping
              </a>
            </cite>
          </figcaption>
        </blockquote>
        <blockquote>
          <p>
            "It's the practice of applying two specific business tools to help
            you make decisions in your complicated personal life: (i) political,
            economic, socio-cultural and technological influences (PEST) and
            (ii) scenario planning."
          </p>
          <p>"I'm a big of fan of scenario planning.."</p>
          <figcaption>
            - Michael Piggott,{' '}
            <cite>
              <a
                href="https://www.marketingsociety.com/the-library/futurescaping"
                target="_blank"
                rel="noreferrer"
              >
                The Marketing Society
              </a>
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  behavioralInterviewQuestions: {
    available: true,
    title: 'Behavioral Interview Questions',
    description: (
      <>
        <blockquote>
          <p>
            "When practicing answers for behavioral interview questions,
            consider following what is called the STAR interview response
            technique. It is a four-step technique for answering questions about
            past behaviors at work: Situation. Describe the situation or set the
            scene. Explain the place you were working for or the task you were
            given. Task. Describe the issue or problem you were confronted with.
            Action. Describe the action you took to intervene in the situation
            or solve the problem. This should introduce the key asset you would
            like to illustrate. Results. Describe the results your action
            generated. Explain how you helped solve the problem or improve the
            company in some way."
          </p>
          <figcaption>
            - Alison Doyle,{' '}
            <cite>
              <a
                href="https://www.thebalancecareers.com/how-to-answer-behavioral-interview-questions-2059622"
                target="_blank"
                rel="noreferrer"
              >
                How to Answer Behavioral Interview Questions
              </a>
              , The Balance Careers
            </cite>
          </figcaption>
        </blockquote>
        <div className="my-4">
          See also:{' '}
          <a
            href="https://www.thebalancecareers.com/what-is-the-star-interview-response-technique-2061629"
            target="_blank"
            rel="noreferrer"
          >
            How to Use the STAR Interview Response Method
          </a>
          , The Balance Careers
        </div>
        <blockquote>
          <p>10 Common Behavioral Interview Questions:</p>
          <ul>
            <li>Tell me about how you worked effectively under pressure.</li>
            <li>How do you handle a challenge? Give an example.</li>
            <li>Have you ever made a mistake? How did you handle it? </li>
            <li>Give an example of how you set goals. </li>
            <li>
              Give an example of a goal you reached and tell me how you achieved
              it.
            </li>
            <li>
              Describe a decision you made that wasn't popular, and explain how
              you handled implementing it.
            </li>
            <li>Give an example of how you worked on a team.</li>
            <li>What do you do if you disagree with someone at work? </li>
            <li>
              Share an example of how you were able to motivate employees or
              co-workers.{' '}
            </li>
            <li>Have you handled a difficult situation? How?</li>
          </ul>
          <figcaption>
            - Alison Doyle,{' '}
            <cite>
              <a
                href="https://www.thebalancecareers.com/top-behavioral-interview-questions-2059618"
                target="_blank"
                rel="noreferrer"
              >
                10 Common Behavioral Interview Questions
              </a>
              , The Balance Careers
            </cite>
          </figcaption>
        </blockquote>
      </>
    ),
  },
  fiveMinuteJournal: {
    available: false,
    title: 'Five Minute Journal',
    description: (
      <>
        <h2>Morning</h2>
        <ol>
          <li>
            I am grateful for...
            <ol>
              <li>____________________</li>
              <li>____________________</li>
              <li>____________________</li>
            </ol>
          </li>
          <li>
            What would make today great?
            <ol>
              <li>____________________</li>
              <li>____________________</li>
              <li>____________________</li>
            </ol>
          </li>
          <li>
            Daily affirmations. I am...
            <ul>
              <li>____________________</li>
              <li>____________________</li>
            </ul>
          </li>
        </ol>
        <h2>Night</h2>
        <ol start={4}>
          <li>
            3 Amazing things that happened today...
            <ol>
              <li>____________________</li>
              <li>____________________</li>
              <li>____________________</li>
            </ol>
          </li>
          <li>
            How could I have made today better?
            <ul>
              <li>____________________</li>
              <li>____________________</li>
            </ul>
          </li>
        </ol>
        <FiveMinuteJournalFigcaption />
      </>
    ),
  },
  fiveMinuteJournalMorning: {
    available: true,
    title: 'Five Minute Journal Morning',
    description: (
      <>
        <h2>Morning</h2>
        <ol>
          <li>
            I am grateful for...
            <ol>
              <li>____________________</li>
              <li>____________________</li>
              <li>____________________</li>
            </ol>
          </li>
          <li>
            What would make today great?
            <ol>
              <li>____________________</li>
              <li>____________________</li>
              <li>____________________</li>
            </ol>
          </li>
          <li>
            Daily affirmations. I am...
            <ul>
              <li>____________________</li>
              <li>____________________</li>
            </ul>
          </li>
        </ol>
        <FiveMinuteJournalFigcaption />
      </>
    ),
  },
  fiveMinuteJournalNight: {
    available: true,
    title: 'Five Minute Journal Night',
    description: (
      <>
        <h2>Night</h2>
        <ol>
          <li>
            3 Amazing things that happened today...
            <ol>
              <li>____________________</li>
              <li>____________________</li>
              <li>____________________</li>
            </ol>
          </li>
          <li>
            How could I have made today better?
            <ul>
              <li>____________________</li>
              <li>____________________</li>
            </ul>
          </li>
        </ol>
        <FiveMinuteJournalFigcaption />
      </>
    ),
  },
  motivation: {
    available: true,
    title: 'Motivation',
    description: (
      <>
        <p>
          To some extent, motivation is unique to each individual. But research
          has shown several strategies that work for most people.
        </p>
        <h2>Design Goals, Not Chores</h2>
        <p>
          Goal setting. Have targets. Make daily committments. Be concrete, not
          abstract. Objectives should be specific.
        </p>
        <p>Goals should trigger intrinsic motivation, seen as its own end.</p>
        <p>More pleasant resolutions, not necessarily more important.</p>
        <p>
          A large enough external reward will keep us at even the most
          unpleasant tasks, but we'll do the minimum required and won't excel.
        </p>
        <p>
          Seek work roles and environments we enjoy. Positive relationships with
          colleagues and managers. Office morale.
        </p>
        <p>
          Focus on the elements of the work that you do find enjoyable. How will
          accomplishing the task feel satisfying? Mix rewarding activities like
          listening to music to get through drudgery.
        </p>
        <h2>Find Effective Rewards</h2>
        <p>
          Create external motivators over short-to-medium term, especially if
          they compliment the rewards of the work, like money to a travel
          vacation. Be sure your goals consider the right metrics, often not
          speed of finishing. Also, don't reward yourself with something that
          negates the accomplishment, like a dieter rewarding with pizza and
          cake, or hard work with slacking off.
        </p>
        <p>
          People work harder for an uncertain reward, eg 50% chance of $50 or
          $150 vs 100% of $100. Gamify task rewards, eg envelopes of rewards to
          be selected after achievement.
        </p>
        <p>
          Loss aversion is a more powerful motivator than gain-based rewards.
        </p>
        <h2>Sustain Progress</h2>
        <p>
          Early burst of motivation usually slumps in the middle. Better in
          beginning and end.
        </p>
        <p>
          Short middles. Smaller goals means shorter middles, less slumping.
        </p>
        <p>
          Think of your starting point as being further in the past, you're
          already closer to earning the reward, don't quit now.
        </p>
        <p>What have you done to get where you are? You've completed a of b!</p>
        <p>What or how many do you have left to do? You're only x from y!</p>
        <h2>Harness the Influence of Others</h2>
        <p>We're social creatures. The actions of others influence our own.</p>
        <p>
          Never passively watch ambitious, efficient, successful coworkers. Ask
          them what they're trying to accomplish and why they would recommend
          doing it. Understanding via endorsement vs only observation.
        </p>
        <p>
          Giving advice can be more motivating than asking for it. It boosts
          confidence.
        </p>
        <p>
          Folks who share a big-picture goal with you: close friends and family
          or mentors. Desire to succeed on their behalf.
        </p>
        <h2>Summary</h2>
        <p>
          It can help to tap the power of intrinsic and extrinsic motivators,
          set incentives carefully, turn our focus either behind or ahead
          depending on how close we are to the finish, and harness social
          influence.
        </p>
        <figcaption>
          <p>Adapted from:</p>- Ayelet Fishbach,{' '}
          <cite>
            <a
              href="https://hbr.org/2018/11/how-to-keep-working-when-youre-just-not-feeling-it"
              target="_blank"
              rel="noreferrer"
            >
              How to Keep Working When You're Just Not Feeling It
            </a>
            , Harvard Business Review
          </cite>
        </figcaption>
      </>
    ),
  },
  memoir: {
    title: 'Memoir',
    available: true,
    description: (
      <>
        <blockquote>
          "A memoir covers one specific aspect of the writer's life."
          <br />-
          https://www.writersdigest.com/write-better-nonfiction/memoir-vs-autobiography-2
        </blockquote>
        <blockquote>
          "Memoirs are a format in which writers use their life experience in
          service of a larger theme or idea."
          <br />-
          https://www.masterclass.com/articles/memoir-and-autobiography-learn-the-differences-and-tips-for-writing-memoir-and-autobiography#what-is-a-memoir
        </blockquote>
        <blockquote>
          These are the key elements of a memoir:
          <ul>
            <li>
              A focused theme. Your memoir should have an overarching theme,
              takeaway lesson, or message for your readers. ...
            </li>
            <li>Conflict. ...</li>
            <li>Writing style. ...</li>
            <li>Use supporting stories and details. ...</li>
            <li>Storytelling elements. ...</li>
            <li>Truth.</li>
          </ul>
          <br />- https://selfpublishing.com/how-to-write-a-memoir/
        </blockquote>
        <blockquote>
          Consider some of the elements that make a good memoir:
          <ul>
            <li>
              Theme. Your memoir's theme will be based on a snapshot of your
              life, something unique that you want to convey to your reader. ...
            </li>
            <li>
              Honesty. To write an effective memoir, it is crucial to keep it
              real. ...
            </li>
            <li>Emotional beats. ...</li>
            <li>Initiating incident. ...</li>
            <li>Obstacles. ...</li>
            <li>Ending incident.</li>
          </ul>
          <br />- https://gatekeeperpress.com/what-makes-a-good-memoir/
        </blockquote>
      </>
    ),
  },
  wisdom: {
    title: 'Wisdom',
    available: true,
  },
  fiction: {
    title: 'Fiction',
    available: true,
  },
  journal: {
    title: 'Journal',
    available: true,
  },
  raiseAmbition: {
    title: 'Raise Ambition',
    available: true,
    description: (
      <>
        <p>
          Video:{' '}
          <a
            href="https://www.youtube.com/watch?v=_UnDjRJbcN0"
            target="_blank"
            rel="noreferrer"
          >
            Raise Your Ambition
          </a>
        </p>
        <p>
          In The 6 Habits of Growth, Brendon Burchard recommends that{' '}
          <em>raising ambition</em> supports motivation, one of the 6 habits of
          growth.
        </p>
        <ul>
          <li>every morning</li>
          <li>reconnect w future</li>
          <li>imagining better</li>
          <li>do not tie it to others' opinions of you</li>
        </ul>
        <p>
          He also recommends <em>positive expectancy</em> - "I can; that's
          possible for me."
        </p>
        <p>Daily Journal:</p>
        <ul>
          <li>Why I can? What I can?</li>
          <li>How I feel about my future/fream?</li>
          <li>Today I'm going to xyz / learn / figure it out.</li>
        </ul>
        <p>Progress Principle</p>
        <ul>
          <li>progress daily; momentum, drive</li>
          <li>with momentum comes motivation</li>
          <li>What are you wanting / fighting for?</li>
          <li>Tell me about the future you're trying to build.</li>
        </ul>
      </>
    ),
  },
  goals: {
    title: 'Goals',
    available: true,
  },
  theAnatomyOfLonelinessAngerUpset: {
    title: 'The Anatomy of Loneliness: Anger/Upset',
    available: true,
    description: (
      <>
        <p>
          <em>
            The following is copied from "Part IV: Creating Connection" in "The
            Anatomy of Loneliness: How to Find Your Way Back to Connection" by
            Teal Swan.
          </em>
        </p>
        <p>
          Think about any upsetting event or situation in your life and write
          anything and everything you can about each of the following emotions.
        </p>
        <ul>
          <li>
            1. Anger
            <ul>
              <li>What am I angry about?</li>
              <li>What or whom do I blame and why?</li>
              <li>Whom or what do I feel resentment for and why?</li>
              <li>It makes me so mad when...</li>
              <li>I'm completely fed up with...</li>
              <li>I hate...</li>
            </ul>
          </li>
          <li>
            2. Pain
            <ul>
              <li>What about this makes me so sad?</li>
              <li>I am so hurt by...</li>
              <li>I feel so disappointed that...</li>
            </ul>
          </li>
          <li>
            3. Fear
            <ul>
              <li>What about this makes me so afraid?</li>
              <li>I'm scared that...</li>
              <li>It scares me when...</li>
              <li>What does it scare me?</li>
              <li>What about this embarrasses me?</li>
              <li>What about this makes me feel insecure?</li>
              <li>
                What is the deep wound hiding underneath the anger and sadness?
              </li>
              <li>What painful thing does this situation remind me of?</li>
            </ul>
          </li>
          <li>
            4. Understanding
            <ul>
              <li>I regret...</li>
              <li>I'm sorry that...</li>
              <li>What part of the situation do I take responsibility for?</li>
              <li>I didn't mean to...</li>
              <li>I understand that...</li>
              <li>I know sometimes that I...</li>
              <li>What do I want forgiveness for?</li>
            </ul>
          </li>
          <li>
            5. Love
            <ul>
              <li>Deep down I have the purest of intentions and they are...</li>
              <li>Deep down in my heart I want...</li>
              <li>I promise to...</li>
              <li>
                What are some solutions to the situation that I can think of?
              </li>
              <li>I hope that...</li>
              <li>I feel gratitude for...</li>
              <li>I forgive...</li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  theAnatomyOfLonelinessSelfAttunementPractice: {
    title: 'The Anatomy of Loneliness: Self Attunement Practice',
    available: true,
    prompt: 'What emotions are you feeling in this exact moment?',
    description: (
      <>
        <p>
          <em>
            The following is copied from "Part IV: Creating Connection" in "The
            Anatomy of Loneliness: How to Find Your Way Back to Connection" by
            Teal Swan.
          </em>
        </p>
        <p>
          Try to tune into your own emotions. Whenever you experience things
          that either cause an emotional reaction or that you think could cause
          an emotional reaction, ask yourself the following questions and be as
          brutally honest as you can.
        </p>
        <ul>
          <li>What was my perception of what happened?</li>
          <li>What was troubling to me about what happened?</li>
          <li>What were the emotions that I felt at the time it happened?</li>
          <li>What emotions am I feeling in this exact moment?</li>
          <li>What do I really need from other people in this circumstance?</li>
        </ul>
        <p>
          Ask yourself, what do I feel like I need to do now given these answers
          I have now become aware of?
        </p>
      </>
    ),
  },
  marandasDailyJournaling: {
    title: "Maranda's Daily Journaling",
    available: true,
    description: (
      <ul>
        <li>3 things to accomplish today</li>
        <li>3 things I like about myself</li>
        <li>1 thing I want to work on</li>
      </ul>
    ),
  },
  currentDesiredState: {
    available: true,
    title: 'Current & Desired State',
    description: (
      <>
        <p>Consider your present moment and potential:</p>
        <ul>
          <li>What are you doing right now?</li>
          <li>What would you rather be doing?</li>
          <li>What's the smallest step between these states?</li>
        </ul>
      </>
    ),
  },
});

export default MODALITIES;
