import React, { useState, useEffect, useCallback } from 'react';
import Card from '../../../shared/Card';
import { Position, HexCoord, HexCell, PlayerState } from './types';
import {
  pixelToHex,
  hexToPixel,
  getMovementDelta,
  HEX_SIZE,
  getNextValidPosition,
  generateHexGrid,
} from './utils';
import './HexTones.css';

// Audio context and sounds
const getAudioContext = () => {
  if (typeof window !== 'undefined') {
    return new (window.AudioContext || window.webkitAudioContext)();
  }
  return null;
};

const notes = ['C4', 'D4', 'E4', 'G4', 'A4'].map((note) => {
  const freq = {
    C4: 261.63,
    D4: 293.66,
    E4: 329.63,
    G4: 392.0,
    A4: 440.0,
  }[note];
  return { note, freq };
});

const playNote = (freq: number) => {
  const audioContext = getAudioContext();
  if (!audioContext) return;

  const oscillator = audioContext.createOscillator();
  const gainNode = audioContext.createGain();

  oscillator.type = 'sine';
  oscillator.frequency.setValueAtTime(freq, audioContext.currentTime);

  gainNode.gain.setValueAtTime(0.5, audioContext.currentTime);
  gainNode.gain.exponentialRampToValueAtTime(
    0.01,
    audioContext.currentTime + 0.5
  );

  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  oscillator.start();
  oscillator.stop(audioContext.currentTime + 0.5);
};

export default function HexTones() {
  const [player, setPlayer] = useState<PlayerState>({
    position: { x: 0, y: 0 },
    currentHex: null,
    moveSpeed: 5,
  });
  const [grid, setGrid] = useState<HexCell[]>([]);

  // Calculate hex points for polygon
  const calculateHexPoints = (pos: Position) => {
    const points = [];
    for (let i = 0; i < 6; i++) {
      const angle = (i * 60 * Math.PI) / 180;
      const x = pos.x + HEX_SIZE * Math.cos(angle);
      const y = pos.y + HEX_SIZE * Math.sin(angle);
      points.push(`${x},${y}`);
    }
    return points.join(' ');
  };

  // Handle continuous movement
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const delta = getMovementDelta(e.code);

      setPlayer((prev) => {
        const newPos = {
          x: prev.position.x + delta.x,
          y: prev.position.y + delta.y,
        };

        // Calculate new hex position
        const newHex = pixelToHex(newPos.x, newPos.y);

        // If entering new hex, trigger sound
        if (JSON.stringify(newHex) !== JSON.stringify(prev.currentHex)) {
          const hexCell = grid.find(
            (cell) => cell.coord.q === newHex.q && cell.coord.r === newHex.r
          );
          if (hexCell) {
            playNote(hexCell.tone);
          }
        }

        return {
          ...prev,
          position: getNextValidPosition(prev.position, newPos, 4),
          currentHex: newHex,
        };
      });
    },
    [grid]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  // Initialize grid
  useEffect(() => {
    setGrid(generateHexGrid(4)); // radius of 4 gives a nice playable area
  }, []);

  return (
    <Card>
      <h1>HexTones</h1>
      <div className="game-container">
        <svg
          className="hex-grid"
          viewBox={`-${HEX_SIZE} -${HEX_SIZE} ${HEX_SIZE * 16} ${
            HEX_SIZE * 16
          }`}
        >
          {/* Render hex grid */}
          {grid.map((cell) => {
            const pos = hexToPixel(cell.coord);
            return (
              <polygon
                key={cell.id}
                points={calculateHexPoints(pos)}
                className={cell.isActive ? 'hex active' : 'hex'}
              />
            );
          })}
          {/* Render player */}
          <circle
            cx={player.position.x}
            cy={player.position.y}
            r={HEX_SIZE / 3}
            className="player"
          />
        </svg>
      </div>
    </Card>
  );
}
