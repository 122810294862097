import { useEffect, useRef } from 'react';
import { ChatMessage } from '../services/ai/types';

export function useAutoScroll(messages: ChatMessage[]) {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return messagesEndRef;
}
