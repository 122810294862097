import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompressAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import getItemIdsByRank from './getItemsByRank';
import './HierarchyDisplay.css';
import { toast } from 'react-toastify';
import { ItemType, Relationship } from '../types';
import useKeyPress from '../hooks/useKeyPress';

interface ItemProps {
  addItemForCompare?: () => void;
  children: React.ReactNode;
  deleteItem?: () => void;
  deleteRelationships?: () => void;
}

// TODO onClick should actually open an Item info details card
const Item = ({
  addItemForCompare,
  children,
  deleteItem,
  deleteRelationships,
}: ItemProps) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const isMetaKeyDown = useKeyPress('Meta');

  const handleDelete = (action: () => void) => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      action();
    }
  };

  useEffect(() => {
    // Add click listener to close dropdown when clicking outside
    const handleClickOutside = () => {
      if (showDropdown) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div
      style={{ display: 'inline-block' }}
      className="mt-1 me-2 mb-2 ms-1"
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      role="button"
      tabIndex={0}
    >
      <DropdownButton
        className="dropdown-toggle-no-arrow"
        show={showDropdown}
        onToggle={(isOpen, event) => {
          if (event?.type === 'contextmenu') {
            setShowDropdown(!showDropdown); // Toggle on right click
          }
        }}
        title={children}
        variant={mouseOver ? 'primary' : 'light'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (addItemForCompare) {
            addItemForCompare();
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDropdown(!showDropdown); // Toggle on right click
        }}
      >
        {addItemForCompare && (
          <Dropdown.Item onClick={addItemForCompare} className="text-primary">
            <FontAwesomeIcon icon={faCompressAlt} className="fa-fw me-2" />
            Compare
          </Dropdown.Item>
        )}
        {(deleteItem || deleteRelationships) && <Dropdown.Divider />}
        {deleteRelationships && (
          <Dropdown.Item
            onClick={() => handleDelete(deleteRelationships)}
            className="text-warning"
          >
            <FontAwesomeIcon icon={faTrashAlt} className="fa-fw me-2" />
            Delete Relationships
          </Dropdown.Item>
        )}
        {deleteItem && (
          <Dropdown.Item
            onClick={() => handleDelete(deleteItem)}
            className="text-danger"
          >
            <FontAwesomeIcon icon={faTrashAlt} className="fa-fw me-2" />
            Delete Item &amp; Relationships
          </Dropdown.Item>
        )}
      </DropdownButton>
    </div>
  );
};

interface HierarchyDisplayProps {
  addItemForCompare: (itemId: string) => void;
  deleteItem: (itemId: string) => void;
  deleteRelationships: (itemId: string) => void;
  items: { [itemId: string]: ItemType };
  relationships: { [relationshipId: string]: Relationship };
}

const HierarchyDisplay = ({
  addItemForCompare,
  deleteItem,
  deleteRelationships,
  items,
  relationships,
}: HierarchyDisplayProps) => {
  const { itemIds, errorMessage } = getItemIdsByRank(relationships, items);

  if (errorMessage) {
    toast(errorMessage);
    return (
      <Item>
        <span style={{ color: 'red' }}>{errorMessage}</span>
      </Item>
    );
  }
  return Object.entries(itemIds)
    .reverse()
    .map(([rank, _items], i) => {
      return (
        <div
          key={rank}
          className="mb-1"
          style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
          }}
        >
          <span>{i + 1}.</span>
          {_items.map((itemId) => (
            <Item
              key={itemId}
              addItemForCompare={() => addItemForCompare(itemId)}
              deleteItem={() => deleteItem(itemId)}
              deleteRelationships={() => deleteRelationships(itemId)}
            >
              {itemId}
            </Item>
          ))}
        </div>
      );
    });
};

export default HierarchyDisplay;
