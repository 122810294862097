import { useState } from 'react';
import Card from '../../../../shared/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';

function ListsList({
  currentListId,
  lists,
  onClick,
}: {
  currentListId: string;
  lists: { [id: string]: { name: string; id: string } };
  onClick: (list: { name: string; id: string }) => void;
}) {
  if (!lists) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '2rem' }}>
      {Object.values(lists)
        .sort((a, b) =>
          a.name?.localeCompare(b.name, undefined, { sensitivity: 'base' })
        )
        .map((list) => {
          const active = currentListId === list.id;
          return (
            <button
              key={list.id}
              onClick={() => onClick(list)}
              onKeyDown={(e) => e.key === 'Enter' && onClick(list)}
              role="tab"
              aria-selected={active}
              style={{
                width: 300,
                color: `${active ? 'white' : ''}`,
                backgroundColor: `${active ? '#0d6efd' : ''}`,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                padding: 5,
                border: 'none',
                cursor: 'pointer',
                textAlign: 'left',
                transition: 'all 0.2s ease',
              }}
            >
              {list.name}
            </button>
          );
        })}
    </div>
  );
}

const AddListForm = ({
  onSubmit,
}: {
  onSubmit: ({
    listName,
    onSuccess,
  }: {
    listName: string;
    onSuccess: () => void;
  }) => void;
}) => {
  const [newListName, setNewListName] = useState<string>('');
  const [error, setError] = useState<string>('');

  const clearNewList = () => {
    setNewListName('');
    setError('');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!newListName.trim()) {
      setError('List name cannot be empty');
      return;
    }

    onSubmit({ listName: newListName.trim(), onSuccess: clearNewList });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <ButtonGroup style={{ width: '100%' }}>
        <Form.Control
          type="text"
          placeholder="list name"
          value={newListName}
          onChange={(e) => {
            setNewListName(e.target.value);
            setError('');
          }}
          autoFocus={true}
          isInvalid={!!error}
        />
        <Button
          style={{ whiteSpace: 'nowrap' }}
          type="submit"
          disabled={!newListName.trim()}
        >
          Create List
        </Button>
      </ButtonGroup>
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </Form>
  );
};

function Lists({
  createList,
  currentListId,
  lists,
  selectListId,
}: {
  createList: (name: string, onSuccess: () => void) => void;
  currentListId: string;
  lists: { [id: string]: { name: string; id: string } };
  selectListId: (listId: string) => void;
}) {
  return (
    <Card title="Choose a list:">
      <div className="mb-3">
        <ListsList
          currentListId={currentListId}
          lists={lists}
          onClick={(list) => selectListId(list.id)}
        />
      </div>
      <AddListForm
        onSubmit={({ listName, onSuccess = () => null }) => {
          createList(listName, onSuccess);
        }}
      />
    </Card>
  );
}

export default Lists;
