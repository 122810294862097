import '../../styles.css';

interface ToolbarProps {
  onNewConversation: () => void;
}

export function Toolbar({ onNewConversation }: ToolbarProps) {
  return (
    <div className="ilu2-toolbar">
      <div className="toolbar-controls">
        <button onClick={onNewConversation} className="toolbar-button danger">
          Clear
        </button>
      </div>
    </div>
  );
}
