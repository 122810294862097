import { Message } from './Message';
import { useAutoScroll } from '../hooks/useAutoScroll';
import { ChatMessage } from '../services/ai/types';

interface ChatMessagesProps {
  messages: ChatMessage[];
}

export function ChatMessages({ messages }: ChatMessagesProps) {
  const messagesEndRef = useAutoScroll(messages);

  return (
    <div className="chat-messages">
      {messages.map((msg) => (
        <Message key={msg.id} text={msg.text} sender={msg.sender} />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}
