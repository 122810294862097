import { emotionalPatterns } from './emotionalPatterns';
import { EmotionalState, AttunementLevel } from './types';

export function analyzeSelfAttunement(input: string) {
  const valueAlignment = assessValueAlignment(input);
  const nextActions = generateActionSuggestions(valueAlignment);

  return {
    valueAlignment,
    nextActions,
  };
}

function assessValueAlignment(input: string) {
  // TODO: Implement based on value hierarchies
  return {
    immortality: 0,
    propagation: 0,
    relation: 0,
  };
}

function generateActionSuggestions(valueAlignment: any): string[] {
  // Direct action suggestions based on value alignment
  return [
    'What specific technological advancement could you work on now?',
    'Which system could you automate or improve?',
    'What would create the most lasting impact?',
  ];
}

export function detectEmotionalState(input: string): EmotionalState {
  const text = input.toLowerCase();
  const emotions = Object.entries(emotionalPatterns).reduce(
    (state, [emotion, intensities]) => {
      const matches = Object.entries(intensities).reduce(
        (intensity, [level, words]) => {
          const found = words.some((word) => text.includes(word));
          if (found) return level;
          return intensity;
        },
        ''
      );

      return {
        ...state,
        [emotion]: matches.length > 0,
        intensity: matches ? getIntensityLevel(matches) : 0,
      };
    },
    {} as EmotionalState
  );

  return {
    ...emotions,
    dominant: getDominantEmotion(emotions),
  };
}

export function calculateAttunementLevel(input: string): AttunementLevel {
  const markerCount = 0; // TODO: Implement based on actual principles

  return {
    level: 0,
    markers: 0,
    suggestions: [], // Commented out until core principles are implemented
  };
}

function generateAttunementSteps(
  emotionalState: EmotionalState,
  attunement: AttunementLevel
) {
  // Reference to The Anatomy of Loneliness questions
  // startLine: 1424
  // endLine: 1483

  if (attunement.level < 0.3) {
    return [
      "Take a moment to notice what you're feeling right now",
      'What physical sensations are present?',
      'What emotions can you name?',
    ];
  }

  return emotionalState.anger || emotionalState.fear
    ? [
        'What do you need in this moment?',
        'What would help you feel more grounded?',
        'What small step could support you right now?',
      ]
    : [
        'What would deepen your understanding?',
        'What possibilities do you see?',
        'What small action feels aligned?',
      ];
}

function getIntensityLevel(level: string): number {
  switch (level) {
    case 'intense':
      return 1;
    case 'medium':
      return 0.6;
    case 'soft':
      return 0.3;
    default:
      return 0;
  }
}

function getDominantEmotion(
  emotions: EmotionalState
): keyof EmotionalState | undefined {
  const emotionEntries = Object.entries(emotions).filter(
    ([key]) => key !== 'intensity' && key !== 'dominant'
  );

  return emotionEntries.reduce((dominant, [emotion, value]) => {
    if (value && (!dominant || emotions[dominant] < value)) {
      return emotion as keyof EmotionalState;
    }
    return dominant;
  }, undefined as keyof EmotionalState | undefined);
}

export function generateAttunementSuggestions(
  markerCount: number,
  emotionalState?: EmotionalState
): string[] {
  // If there's strong emotion but we want to stay productive
  if (emotionalState?.intensity && emotionalState.intensity > 0.7) {
    return [
      "If you're open to it - you seem to have strong feelings about this. While honoring that, what should you do?",
      'Acknowledging your perspective here - what specific action would move things forward?',
      "With respect to how you're feeling - what's the next concrete step?",
    ];
  }

  // Default to action-oriented questions
  if (markerCount === 0 || !markerCount) {
    return [
      'What should you do?',
      'What should you be doing?',
      "What's the next concrete step?",
    ];
  }

  return [
    'What should you do?',
    'What specific action makes sense now?',
    "What's the immediate next step?",
  ];
}
