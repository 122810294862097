export const emotionalPatterns = {
  anger: {
    soft: ['annoyed', 'frustrated', 'impatient', 'irritated'],
    medium: ['angry', 'mad', 'resentful'],
    intense: ['furious', 'hateful', 'hostile', 'outraged'],
  },
  fear: {
    soft: ['uncertain', 'unsure', 'nervous'],
    medium: ['anxious', 'worried', 'scared'],
    intense: ['terrified', 'panicked', 'petrified'],
  },
  sad: {
    soft: ['disappointed', 'down', 'unhappy'],
    medium: ['hurt', 'lonely', 'grief'],
    intense: ['devastated', 'hopeless', 'miserable'],
  },
  happy: {
    soft: ['content', 'pleasant', 'calm'],
    medium: ['grateful', 'pleased', 'satisfied'],
    intense: ['excited', 'joyful', 'ecstatic'],
  },
};
