import { Position, HexCoord, HexCell } from './types';

export const HEX_SIZE = 50;
export const MOVEMENT_SPEED = 5;

// Define musical notes and frequencies
export const notes = [
  { note: 'C4', freq: 261.63 },
  { note: 'D4', freq: 293.66 },
  { note: 'E4', freq: 329.63 },
  { note: 'G4', freq: 392.0 },
  { note: 'A4', freq: 440.0 },
];

// Check if position is within hex bounds
export function isWithinHexBounds(pos: Position, radius: number): boolean {
  const hex = pixelToHex(pos.x, pos.y);
  return (
    Math.abs(hex.q) <= radius &&
    Math.abs(hex.r) <= radius &&
    Math.abs(hex.q + hex.r) <= radius
  );
}

// Get next valid position considering boundaries
export function getNextValidPosition(
  current: Position,
  next: Position,
  radius: number
): Position {
  if (isWithinHexBounds(next, radius)) {
    return next;
  }

  // If next position is invalid, try to slide along the boundary
  const slideX = { x: next.x, y: current.y };
  const slideY = { x: current.x, y: next.y };

  if (isWithinHexBounds(slideX, radius)) return slideX;
  if (isWithinHexBounds(slideY, radius)) return slideY;

  return current;
}

// Convert pixel position to hex coordinates
export function pixelToHex(x: number, y: number): HexCoord {
  const q = ((2 / 3) * x) / HEX_SIZE;
  const r = ((-1 / 3) * x + (Math.sqrt(3) / 3) * y) / HEX_SIZE;
  return {
    q: Math.round(q),
    r: Math.round(r),
  };
}

// Convert hex coordinates to pixel position
export function hexToPixel(hex: HexCoord): Position {
  const x = HEX_SIZE * ((3 / 2) * hex.q);
  const y = HEX_SIZE * ((Math.sqrt(3) / 2) * hex.q + Math.sqrt(3) * hex.r);
  return { x, y };
}

export function generateHexGrid(radius: number): HexCell[] {
  const grid: HexCell[] = [];
  for (let q = -radius; q <= radius; q++) {
    for (let r = -radius; r <= radius; r++) {
      if (Math.abs(q + r) <= radius) {
        // Create a pentatonic scale pattern based on hex position
        const noteIndex =
          (((q * 2 + r * 3) % notes.length) + notes.length) % notes.length;
        grid.push({
          id: `${q},${r}`,
          coord: { q, r },
          tone: notes[noteIndex].freq,
          isActive: false,
        });
      }
    }
  }
  return grid;
}

export function smoothMovement(
  current: number,
  target: number,
  speed: number
): number {
  const diff = target - current;
  const delta = Math.min(Math.abs(diff), speed) * Math.sign(diff);
  return current + delta;
}

// Get movement delta based on key
export function getMovementDelta(key: string): Position {
  const deltas = {
    KeyW: { x: 0, y: -MOVEMENT_SPEED },
    KeyS: { x: 0, y: MOVEMENT_SPEED },
    KeyA: { x: -MOVEMENT_SPEED, y: 0 },
    KeyD: { x: MOVEMENT_SPEED, y: 0 },
  };
  return deltas[key] || { x: 0, y: 0 };
}
