import { cast } from '../../../../../../utils/cast';
import { Id, ItemType, Relationship } from '../types';
import {
  itemRef,
  itemsRef,
  relationshipRef,
  relationshipsRef,
} from './db-refs';

function getItems(
  userId: Id,
  listId: Id,
  callback: (items: Record<string, ItemType>) => void
) {
  const items = {} as Record<string, ItemType>;
  itemsRef({ uid: userId, listId })
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((item) => {
        items[item.id] = { id: item.id, ...cast<ItemType>(item.data()) };
      });
      callback && typeof callback === 'function' && callback(items);
    });
}

//TODO works for create. does this work for update?
function upsertItem(
  userId: Id,
  listId: Id,
  item: ItemType,
  callback: (itemId: Id) => void
) {
  if (!item.id) return;
  itemRef({ uid: userId, listId, id: item.id })
    .set(item, { merge: true })
    .then(() => {
      callback && typeof callback === 'function' && callback(item.id);
    });
}

function deleteItemAndRelationships(
  userId: Id,
  listId: Id,
  itemId: Id,
  callback: () => void
) {
  if (!itemId) return;
  itemRef({ uid: userId, listId, id: itemId }).delete();
  deleteItemRelationships(userId, listId, itemId);
  callback && typeof callback === 'function' && callback();
}

function getRelationships(
  userId: Id,
  listId: Id,
  callback: (
    relationships: Record<string, Relationship & { id: string }>
  ) => void
) {
  const relationships = {} as Record<string, Relationship & { id: string }>;
  relationshipsRef({ uid: userId, listId })
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((relationship) => {
        relationships[relationship.id] = {
          id: relationship.id,
          ...cast<Relationship>(relationship.data()),
        };
      });
      callback && typeof callback === 'function' && callback(relationships);
    });
}

//TODO works for create. does this work for update?
function upsertRelationship(
  userId: Id,
  listId: Id,
  relationship: Relationship & { id: Id },
  callback: (relationshipId: Id) => void
) {
  if (!relationship.options || !relationship.selection) return;
  relationshipRef({
    uid: userId,
    listId,
    id: relationship.id,
  })
    .set(relationship, { merge: true })
    .then(() => {
      callback && typeof callback === 'function' && callback(relationship.id);
    });
}

function deleteRelationship(
  userId: Id,
  listId: Id,
  relationshipId: Id,
  callback?: () => void
) {
  if (!relationshipId) return;
  relationshipRef({
    uid: userId,
    listId,
    id: relationshipId,
  }).delete();
  callback && typeof callback === 'function' && callback();
}

const getItemRelationships = (
  userId: Id,
  listId: Id,
  itemId: Id,
  callback: (
    relationships: Record<string, Relationship & { id: string }>
  ) => void
) => {
  const relationships = {} as Record<string, Relationship & { id: string }>;
  relationshipsRef({ uid: userId, listId })
    .where('options', 'array-contains', itemId)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((relationship) => {
        relationships[relationship.id] = {
          id: relationship.id,
          ...cast<Relationship>(relationship.data()),
        };
      });
      callback && typeof callback === 'function' && callback(relationships);
    });
};
function deleteItemRelationships(
  userId: Id,
  listId: Id,
  itemId: Id,
  callback?: (relationships: unknown) => void
) {
  getItemRelationships(userId, listId, itemId, (relationships) => {
    Object.keys(relationships).forEach((relationshipId) => {
      deleteRelationship(userId, listId, relationshipId);
    });
    callback && typeof callback === 'function' && callback(relationships);
  });
}

export {
  getItems,
  upsertItem,
  deleteItemAndRelationships,
  getRelationships,
  upsertRelationship,
  deleteRelationship,
  deleteItemRelationships,
};
