import { EmotionalState } from './types';
import { emotionalPatterns } from '../../analysis/emotionalPatterns';

const ATTUNEMENT_MARKERS = [
  'feel',
  'sense',
  'notice',
  'aware',
  'body',
  'emotion',
  'experience',
];

const INTEGRATION_MARKERS = [
  'understand',
  'realize',
  'connect',
  'pattern',
  'meaning',
  'perspective',
];

export function detectEmotionalState(input: string): EmotionalState {
  const text = input.toLowerCase();
  const emotions = Object.entries(emotionalPatterns).reduce(
    (state, [emotion, intensities]) => {
      const matches = Object.entries(intensities).reduce(
        (intensity, [level, words]) => {
          const found = words.some((word) => text.includes(word));
          if (found) return level;
          return intensity;
        },
        ''
      );

      return {
        ...state,
        [emotion]: matches.length > 0,
        intensity: matches ? getIntensityLevel(matches) : 0,
      };
    },
    {} as EmotionalState
  );

  return {
    ...emotions,
    dominant: getDominantEmotion(emotions),
  };
}

export function generateResponse(
  input: string,
  emotionalState: EmotionalState
): string {
  if (emotionalState.intensity && emotionalState.intensity > 0.7) {
    return "If you're open to it - you seem to have strong feelings about this. While honoring that, what should you do?";
  }

  if (emotionalState.anger || emotionalState.fear) {
    return 'What do you need right now to feel more grounded?';
  }

  if (emotionalState.sad) {
    return 'What kind of support would feel helpful in this moment?';
  }

  if (emotionalState.happy) {
    return 'How might you build on this positive energy?';
  }

  return 'What small step would help you move forward?';
}

function calculateIntensity(input: string): number {
  const attunementCount = ATTUNEMENT_MARKERS.filter((m) =>
    input.toLowerCase().includes(m)
  ).length;

  const integrationCount = INTEGRATION_MARKERS.filter((m) =>
    input.toLowerCase().includes(m)
  ).length;

  return Math.min(1, (attunementCount + integrationCount) / 10);
}

function getDominantEmotion(
  emotions: EmotionalState
): keyof EmotionalState | undefined {
  // Minimal implementation that prevents crashes
  return undefined;
}

function getIntensityLevel(level: string): number {
  // Minimal implementation that prevents crashes
  return 0;
}
