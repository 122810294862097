import { useEffect, useRef } from 'react';

const TILE_SIZE = 32;
const PLAYER_COLOR = '#ff0000';
const GRID_COLOR = '#333333';
const BG_COLOR = '#222222';
const GAMEPAD_DEADZONE = 0.1;

type Position = {
  x: number;
  y: number;
};

export default function PS2() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const playerPos = useRef<Position>({ x: 0, y: 0 });
  const animationFrameRef = useRef<number>();

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d')!;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    function handleGamepad() {
      const gamepad = navigator.getGamepads()?.[0];
      if (gamepad) {
        // Left analog stick
        const axisX = gamepad.axes[0];
        const axisY = gamepad.axes[1];

        if (Math.abs(axisX) > GAMEPAD_DEADZONE) {
          playerPos.current.x += Math.sign(axisX);
        }
        if (Math.abs(axisY) > GAMEPAD_DEADZONE) {
          playerPos.current.y += Math.sign(axisY);
        }

        // D-pad
        if (gamepad.buttons[14].pressed) playerPos.current.x--; // Left
        if (gamepad.buttons[15].pressed) playerPos.current.x++; // Right
        if (gamepad.buttons[12].pressed) playerPos.current.y--; // Up
        if (gamepad.buttons[13].pressed) playerPos.current.y++; // Down
      }
    }

    function gameLoop() {
      handleGamepad();
      drawGame();
      animationFrameRef.current = requestAnimationFrame(gameLoop);
    }

    function drawGame() {
      // Clear canvas
      ctx.fillStyle = BG_COLOR;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw grid
      ctx.strokeStyle = GRID_COLOR;
      const centerX = Math.floor(canvas.width / 2);
      const centerY = Math.floor(canvas.height / 2);

      // Draw grid lines
      for (let x = centerX % TILE_SIZE; x < canvas.width; x += TILE_SIZE) {
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, canvas.height);
        ctx.stroke();
      }
      for (let y = centerY % TILE_SIZE; y < canvas.height; y += TILE_SIZE) {
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(canvas.width, y);
        ctx.stroke();
      }

      // Draw player
      ctx.fillStyle = PLAYER_COLOR;
      const screenX = centerX + playerPos.current.x * TILE_SIZE;
      const screenY = centerY + playerPos.current.y * TILE_SIZE;
      ctx.beginPath();
      ctx.arc(screenX, screenY, TILE_SIZE / 3, 0, Math.PI * 2);
      ctx.fill();
    }

    function handleKeyDown(e: KeyboardEvent) {
      switch (e.code) {
        case 'ArrowUp':
        case 'KeyW':
          playerPos.current.y--;
          break;
        case 'ArrowDown':
        case 'KeyS':
          playerPos.current.y++;
          break;
        case 'ArrowLeft':
        case 'KeyA':
          playerPos.current.x--;
          break;
        case 'ArrowRight':
        case 'KeyD':
          playerPos.current.x++;
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('gamepadconnected', gameLoop);

    // Start game loop anyway for keyboard controls
    gameLoop();

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('gamepadconnected', gameLoop);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
      }}
    />
  );
}
