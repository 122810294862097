export interface RealityAction {
  type: 'financial' | 'physical' | 'relational' | 'legacy';
  immediacy: number; // 0-1 scale of how urgent
  impact: {
    freedom: number;
    durability: number;
    propagation: number;
  };
  concrete_steps: string[];
  blockers: string[];
  next_action: string;
}

export function generateNextAction(context: string): RealityAction {
  // Focus on immediate real-world impact
  return {
    type: 'financial', // Default to financial freedom as key enabler
    immediacy: 1,
    impact: {
      freedom: 1,
      durability: 0.8,
      propagation: 0.7,
    },
    concrete_steps: [
      'Review current income streams',
      'Identify highest leverage opportunities',
      'Take action on most impactful next step',
    ],
    blockers: ['Time management', 'Energy allocation', 'Task switching costs'],
    next_action:
      'What specific action would most increase your financial freedom right now?',
  };
}
