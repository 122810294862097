import { useContext } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import AppContext from './AppContext';
import Page from './Page';
import About from './views/About';
import Admin from './views/Admin';
import PrivatePosts from './views/Private/Posts';
import AI from './views/Apps/AI';
import Blog from './views/Blog';
import Dashboard from './views/Apps/Dashboard';
import DoNow from './views/Apps/DoNow';
import LifeOptimizer from './views/Apps/LifeOptimizer';
import { ListsPage } from './views/lists/src';
import Names from './views/Apps/Names';
import ScoreKeeper from './views/Apps/ScoreKeeper';
import Community from './views/Community';
import Donate from './views/Donate';
import Events from './views/Events';
import HexTones from './views/Games/HexTones';
import EventPage from './views/Events/EventPage';
import NewEvent from './views/Events/NewEvent';
import Chess from './views/Games/Chess';
import Cultivation from './views/Games/Cultivation';
import FightSim from './views/Games/FightSim';
import IncrementalClickerGame from './views/Games/IncrementalClicker';
import Jackbox from './views/Games/Jackbox';
import Games from './views/Games';
import SimCity from './views/Games/SimCity';
import TicTacToe from './views/Games/TicTacToe';
import TimeBalls from './views/Games/TimeBalls';
import InviteCodes from './views/InviteCodes';
import PageDoesNotExist from './views/PageDoesNotExist';
import PremiumPage from './views/Premium';
import Queues from './views/Queues';
import Rooms from './views/Rooms';
import Room from './views/Rooms/Room';
import Settings from './views/Settings';
import Sitemap from './views/Sitemap';
import Training from './views/Training';
import UserProfile from './views/UserProfile';
import Write from './views/Write';
import Spinner from './shared/Spinner';
import Chiv from './views/Games/Chiv';
import ChessGPT from './views/Games/ChessGPT';
import HappyIslandAdventureGame from './views/Games/HappyIslandAdventure/HappyIsland';
import Index from './views/Index';
import ILU2 from './views/ilu2';
import PS from './views/PS';

const Routes = () => {
  const { loadingUser, user } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  if (loadingUser) {
    return <Spinner size="lg" />;
  }

  // redirect old post links to general post link
  // TODO make this obsolete and get rid of it
  if (location.pathname.startsWith('/posts/')) {
    history.push('/r/general' + location.pathname);
  }

  return (
    <Switch>
      <Route path="/ilu2">
        <Page>
          <ILU2 />
        </Page>
      </Route>
      <Route
        exact
        path="/private/posts"
        key={location.pathname}
        render={(props) => <Redirect to="/private" />}
      />
      <Route
        exact
        path="/private"
        key={location.pathname}
        render={(props) => (
          <Page>
            <PrivatePosts {...props} />
          </Page>
        )}
      />
      <Route
        path="/ps"
        key="/ps"
        render={(props) => (
          <Page loginRequired={false} title="PS | xBook">
            <PS {...props} />
          </Page>
        )}
      />
      <Route
        path="/jackbox"
        key={location.pathname}
        render={(props) => (
          <Page title="Jackbox | xBook">
            <Jackbox {...props} />
          </Page>
        )}
      />
      {user && user.admin && (
        <Route
          path="/admin"
          key="/admin"
          render={(props) => (
            <Page title="Admin | xBook">
              <Admin {...props} />
            </Page>
          )}
        />
      )}
      {user && user.admin && (
        <Route
          path="/ai"
          key="/ai"
          render={(props) => (
            <Page title="AI | xBook">
              <AI {...props} />
            </Page>
          )}
        />
      )}
      [
      <Route
        path="/about"
        key="/about"
        render={(props) => (
          <Page title="About | xBook">
            <About {...props} />
          </Page>
        )}
      />
      ,
      <Route
        path="/blog"
        key="/blog"
        render={(props) => (
          <Page title="Blog | xBook">
            <Blog {...props} />
          </Page>
        )}
      />
      ,
      <Route
        path="/chess-world"
        key="/chess-world"
        render={(props) => (
          <Page title="Chess World | xBook">
            <Cultivation {...props} />
          </Page>
        )}
      />
      ,
      <Route
        path="/cultivation"
        key="/cultivation"
        render={(props) => (
          <Page title="Cultivation Game | xBook">
            <Cultivation {...props} />
          </Page>
        )}
      />
      ,
      <Route
        path="/donow"
        key="/donow"
        render={(props) => (
          <Page title="Do Now">
            <DoNow {...props} />
          </Page>
        )}
      />
      ,
      <Route
        path="/events/:eventId"
        key={location.pathname}
        render={(props) => (
          <Page title="Event | xBook">
            <EventPage {...props} />
          </Page>
        )}
      />
      ,
      <Route
        path="/lists"
        key="/lists"
        render={(props) => (
          <Page title="Lists | xBook">
            <ListsPage {...props} />
          </Page>
        )}
      />
      ,
      <Route
        exact
        path="/r/:roomId"
        key={location.pathname}
        render={(props) => (
          <Page>
            <Room {...props} />
          </Page>
        )}
      />
      ,{/* TODO should not need room path for viewing a post */}
      <Route
        path="/r/:roomId/posts/:postId"
        key={location.pathname}
        render={(props) => (
          //TODO title = post author's display name (like fb) ?
          <Page title="xBook">
            <Room {...props} />
          </Page>
        )}
      />
      ,
      <Route
        path="/simcity"
        key={location.pathname}
        render={(props) => (
          <Page title="Sim City | xBook">
            <SimCity {...props} />
          </Page>
        )}
      />
      ,
      <Route
        path="/sitemap"
        key={location.pathname}
        render={(props) => (
          <Page title="Sitemap | xBook">
            <Sitemap {...props} />
          </Page>
        )}
      />
      ,
      <Route
        path="/time-balls"
        key="/time-balls"
        render={(props) => (
          <Page title="Time Balls | xBook">
            <TimeBalls {...props} />
          </Page>
        )}
      />
      ,
      <Route
        exact
        path="/write"
        key={location.pathname}
        render={(props) => (
          <Page title="Write | xBook">
            <Write {...props} />
          </Page>
        )}
      />
      ,
      <Route
        exact
        path="/"
        key={location.pathname}
        render={(props) => (
          <Page title="xBook" loginRequired={false}>
            <Index {...props} />
          </Page>
        )}
      />
      ,
      {/**
       *
       * REQUIRES USER IS LOGGED IN EVEN JUST TO RENDER OF ROUTE/PAGE
       *
       */}
      {/* TODO probably none of these should 404 if user not logged in; they should invite the user to login or register to see the content they're missing */}
      {user && [
        // <Route
        //   exact
        //   path="/goodquestions"
        //   key={location.pathname}
        //   render={(props) => <Page title="Good Questions">PATH PARKED</Page>}
        // />,
        <Route
          exact
          path="/public"
          key={location.pathname}
          render={(props) => (
            <Page title="Public | xBook">
              <Room {...props} />
            </Page>
          )}
        />,
        <Route
          path="/chess"
          key="/chess"
          render={(props) => (
            <Page title="Chess | xBook">
              <Chess {...props} />
            </Page>
          )}
        />,
        <Route
          path="/chessgpt"
          key="/chessgpt"
          render={(props) => (
            <Page title="ChessGPT | xBook">
              <ChessGPT {...props} />
            </Page>
          )}
        />,
        <Route
          path="/chiv"
          key="/chiv"
          render={(props) => (
            <Page title="Chiv | xBook">
              <Chiv {...props} />
            </Page>
          )}
        />,
        <Route
          path="/community"
          key="/community"
          render={(props) => (
            <Page title="Community | xBook">
              <Community {...props} />
            </Page>
          )}
        />,
        <Route
          path="/dashboard"
          key="/dashboard"
          render={(props) => (
            <Page title="Dashboard | xBook">
              <Dashboard {...props} />
            </Page>
          )}
        />,
        <Route
          path="/donate"
          key="/donate"
          render={(props) => (
            <Page title="Donate | xBook">
              <Donate {...props} />
            </Page>
          )}
        />,
        <Route
          exact
          path="/events"
          key={location.pathname}
          render={(props) => (
            <Page title="Events | xBook" loginRequired={false}>
              <Events {...props} />
            </Page>
          )}
        />,
        <Route
          exact
          path="/events/new"
          key={location.pathname}
          render={(props) => (
            <Page title="New Event | xBook">
              <NewEvent {...props} />
            </Page>
          )}
        />,
        <Route
          exact
          path="/fight-sim"
          key={location.pathname}
          render={(props) => (
            <Page title="Fight Sim | xBook">
              <FightSim />
            </Page>
          )}
        />,
        <Route
          exact
          path="/happy-island"
          key={location.pathname}
          render={(props) => (
            <Page title="Happy Island Adventure Game | xBook">
              <HappyIslandAdventureGame />
            </Page>
          )}
        />,
        <Route
          path="/game"
          key="/game"
          render={(props) => (
            <Page title="Hex Tones | xBook">
              <HexTones {...props} />
            </Page>
          )}
        />,
        <Route
          path="/games"
          key={location.pathname}
          render={(props) => (
            <Page title="Games | xBook">
              <Games {...props} />
            </Page>
          )}
        />,
        <Route
          path="/hextones"
          key="/hextones"
          render={(props) => (
            <Page title="Hex Tones | xBook">
              <HexTones {...props} />
            </Page>
          )}
        />,
        <Route
          path="/hex-tones"
          key="/hex-tones"
          render={(props) => (
            <Page title="Hex Tones | xBook">
              <HexTones {...props} />
            </Page>
          )}
        />,
        <Route
          path="/incremental-clicker-game"
          key="/incremental-clicker-game"
          render={(props) => (
            <Page title="Incremental Clicker Game | xBook">
              <IncrementalClickerGame {...props} />
            </Page>
          )}
        />,
        <Route
          path="/invitecodes"
          key="/invitecodes"
          render={(props) => (
            <Page title="Invite Codes | xBook">
              <InviteCodes {...props} />
            </Page>
          )}
        />,
        <Route
          path="/lifeoptimizer"
          key="/lifeoptimizer"
          render={(props) => (
            <Page title="Life Optimizer | xBook">
              <LifeOptimizer {...props} />
            </Page>
          )}
        />,
        <Route
          path="/names"
          key="/names"
          render={(props) => (
            <Page title="Names | xBook">
              <Names {...props} />
            </Page>
          )}
        />,
        <Route
          path="/premium"
          key="/premium"
          render={(props) => (
            <Page title="Premium | xBook">
              <PremiumPage {...props} />
            </Page>
          )}
        />,
        <Route
          path="/queues"
          key="/queues"
          render={(props) => (
            <Page title="Queues | xBook">
              <Queues {...props} />
            </Page>
          )}
        />,
        <Route
          exact
          path="/r"
          key={location.pathname}
          render={(props) => (
            <Page title="Rooms | xBook">
              <Rooms {...props} />
            </Page>
          )}
        />,
        <Route
          path="/rooms"
          key={location.pathname}
          render={(props) => (
            <Page title="Rooms | xBook">
              <Rooms {...props} />
            </Page>
          )}
        />,
        <Route
          path="/scorekeeper"
          key="/scorekeeper"
          render={(props) => (
            <Page title="Score Keeper | xBook">
              <ScoreKeeper {...props} />
            </Page>
          )}
        />,
        <Route
          path="/settings"
          key="/settings"
          render={(props) => (
            <Page title="Settings | xBook">
              <Settings {...props} />
            </Page>
          )}
        />,
        <Route
          path="/tictactoe"
          key="/tictactoe"
          render={(props) => (
            <Page title="Pente | xBook">
              <TicTacToe {...props} />
            </Page>
          )}
        />,
        <Route
          path="/training/:modalityId"
          key={location.pathname}
          render={(props) => (
            <Page title="Training | xBook">
              <Training {...props} />
            </Page>
          )}
        />,
        <Route
          path="/training"
          key="/training"
          render={(props) => (
            <Page title="Training | xBook">
              <Training {...props} />
            </Page>
          )}
        />,
        <Route
          path="/u/:userId"
          key={location.pathname}
          render={(props) => (
            <Page>
              <UserProfile {...props} />
            </Page>
          )}
        />,
      ]}
      <Route
        key="404"
        render={(props) => (
          <Page title="404 | xBook">
            <PageDoesNotExist {...props} />
          </Page>
        )}
      />
    </Switch>
  );
};

export default Routes;
