import { useState } from 'react';
import { Toolbar } from './components/Toolbar';
import { ChatMessages } from './components/ChatMessages';
import { InputArea } from './components/InputArea';
import { useChat } from './hooks/useChat';
import styles from './styles.module.css';

export default function ILU2() {
  const { messages, input, setInput, handleSubmit, initializeConversation } =
    useChat();

  return (
    <div className={styles.container}>
      <Toolbar onNewConversation={initializeConversation} />
      <ChatMessages messages={messages} />
      <InputArea value={input} onChange={setInput} onSubmit={handleSubmit} />
    </div>
  );
}
