import { useRef } from 'react';
import { useAutoResize } from '../hooks/useAutoResize';

interface InputAreaProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

export function InputArea({ value, onChange, onSubmit }: InputAreaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useAutoResize(textareaRef, value);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <div className="input-container">
      <textarea
        ref={textareaRef}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyPress={handleKeyPress}
        rows={1}
        autoFocus
      />
      <button onClick={onSubmit}>Send</button>
    </div>
  );
}
