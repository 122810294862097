import { detectEmotionalState, generateResponse } from './analysis';
import { generateNextAction } from '../../analysis/realityAction';
import type { AIResponse } from './types';

export async function processUserInput(input: string): Promise<AIResponse> {
  const emotionalState = detectEmotionalState(input);
  const realityAction = generateNextAction(input);

  const suggestions =
    emotionalState.intensity && emotionalState.intensity > 0.7
      ? [
          "If you're open to it - you seem to have strong feelings about this. While honoring that, what should you do?",
          'Acknowledging your perspective here - what specific action would move things forward?',
          realityAction.next_action,
        ]
      : [
          'What should you do?',
          'What specific action makes sense now?',
          realityAction.next_action,
        ];

  return {
    reflection: generateResponse(input, emotionalState),
    analysis: {
      state: emotionalState,
      attunementLevel: emotionalState.intensity || 0,
      suggestions,
    },
    meta: {
      confidence: realityAction.impact.durability,
      transformationPotential: realityAction.impact.propagation,
    },
  };
}
